import { Link, useLocation } from "react-router"
import { Instagram } from "../common/instagram"
import { Pinterest } from "../common/pinterest"
import { ArrowDown } from "../common/arrow_down"
import { Whatsapp } from "../common/whatsapp"

export const Footer = () => {
  const location = useLocation();

  const onContactClick = () => {
    if (location.pathname === "/contato") {
      const form = document.querySelector(".ContatoPage .Layout-50.Section-text");
      if (!form) {
        return;
      }

      const pos = form.getBoundingClientRect();
      window.scrollTo({ top: window.scrollY + pos.top - 90, left: 0, behavior: "auto" })
    }
  }

  return (
    <footer>
      <div className="Footer-left">
        <div className="Footer-logo-container">
          <img src={process.env.PUBLIC_URL + '/assets/logo_vertical_2.avif'} />
        </div>

        <div className="Footer-contact">
          <p>
            <a href="mailto:fabia@teixeira.arq.br" className="underline">
              fabia@teixeira.arq.br
            </a>
          </p>
          <p>
            <a href="tel:+5541991817898">
              (41) 99181.7898
            </a>
          </p>
          <br />
          <p>Av. Anita Garibaldi, 850 | sala 502c</p>
          <p>Cabral | Curitiba | Paraná | 80540-180</p>
        </div>
      </div>

      <div className="Footer-spacer">
      </div>

      <div className="Footer-social Footer-center">
        <a href="https://www.instagram.com/fabiateixeiraarquitetura/">
          <Instagram />
        </a>
        <a href="https://br.pinterest.com/FABIATEIXEIRAARQUITETURA/">
          <Pinterest />
        </a>
      </div>

      <div className="Footer-talk Footer-center">
        <Link to="/contato" className="underline" onClick={onContactClick}>
          VAMOS CONVERSAR <ArrowDown />
        </Link>
      </div>

      <div className="Footer-whatsapp Footer-center">
        <a href="https://wa.me/5541987722396?text=">
          <Whatsapp />
        </a>
      </div>

    </footer>
  )
}
