import { useEffect } from 'react';
import { useLocation } from 'react-router';

export type IPageTitle = {
  title: string,
}

export const PageTitle = ({ title }: IPageTitle) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title;
  }, [location, title]);

  return null;
};

