import { PageTitle } from '../../components/page-title'
import './curadoria.scss'
import { Exposition } from './exposition'
import { GaleriaHeader } from './galeria-header'

export const Curadoria = () => {
  return (
    <div className='Curadoria'>
      <PageTitle title="Fábia Teixeira | Curadoria" />

      <GaleriaHeader />

      <div className="Curadoria-title">
        Curadoria
      </div>

      <Exposition images={[
        "/assets/galeria/interior_1/Prancheta 1.png",
        "/assets/galeria/interior_1/Prancheta 2.png",
        "/assets/galeria/interior_1/Prancheta 3.png",
        "/assets/galeria/interior_1/Prancheta 4.png",
        "/assets/galeria/interior_1/Prancheta 5.png",
        "/assets/galeria/interior_1/Prancheta 6.png",
        "/assets/galeria/interior_1/Prancheta 7.png",
        "/assets/galeria/interior_1/Prancheta 8.png",
      ]} />
      <div className='Curadoria-description'>
        <p className='Typography-title'>
          Casa Reserva
          <br />
          Arquitetura imponente e conforto atemporal
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Além do projeto arquitetônico, os interiores foram desenhados com a mesma sensibilidade e atenção aos detalhes. O mobiliário, as cores e os acabamentos foram escolhidos para complementar a estética da residência, criando um ambiente acolhedor, elegante e funcional. Materiais nobres e de alta qualidade foram combinados com soluções de marcenaria, garantindo um espaço que é ao mesmo tempo sofisticado e prático.
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              O resultado final é uma casa que não apenas atende às necessidades do dia a dia de uma família moderna, mas que também se destaca como uma obra de arte funcional, projetada para ser um ícone atemporal. Com uma planta que equilibra o luxo e a praticidade, o projeto Holtz é um exemplo de como arquitetura de alta qualidade pode transformar o cotidiano de seus moradores, criando um lar que é, ao mesmo tempo, sofisticado e acolhedor.
            </p>
          </div>
        </div>
      </div>

      <Exposition images={[
        "/assets/galeria/interior_2/Prancheta 1.png",
        "/assets/galeria/interior_2/Prancheta 2.png",
        "/assets/galeria/interior_2/Prancheta 3.png",
        "/assets/galeria/interior_2/Prancheta 4.png",
        "/assets/galeria/interior_2/Prancheta 5.png",
        "/assets/galeria/interior_2/Prancheta 6.png",
        "/assets/galeria/interior_2/Prancheta 7.png",
        "/assets/galeria/interior_2/Prancheta 8.png",
      ]} />
      <div className='Curadoria-description'>
        <p className='Typography-title'>
          Casa Equilíbrio
          <br />
          Elegância clássica e contemporânea para Família
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Para o projeto de interiores desta casa, a arquiteta Fábia Teixeira foi desafiada a equilibrar a personalidade clássica dos clientes com a linguagem contemporânea e sofisticada do seu escritório. O resultado é um espaço que reflete a elegância atemporal dos clientes sem abrir mão da modernidade que define a identidade do projeto.
              <br />
              A paleta de cores neutras, com predominância de tons suaves de cinza e bege, foi cuidadosamente escolhida para criar uma atmosfera de tranquilidade e equilíbrio. A ideia foi construir um ambiente acolhedor, sem excessos, onde cada elemento tem seu papel funcional e estético, proporcionando espaços de convivência práticos e eficientes. A simplicidade é o guia, e os poucos, mas impactantes, elementos decorativos garantem sofisticação sem sobrecarregar o ambiente.
              <br />
              Um destaque especial do projeto é o quarto da filha, pensado para proporcionar um espaço lúdico e, ao mesmo tempo, elegante. O detalhe do mezanino foi uma solução criativa para ampliar a área de brinquedos, permitindo que o quarto acompanhasse o crescimento da criança. Essa solução garante uma atmosfera jovem e atual, sem perder a sofisticação,
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              criando um ambiente que será funcional e encantador por muitos anos.
              <br />
              Nos quartos e demais ambientes da casa, a abordagem foi a mesma: personalizar os espaços para refletir a identidade de cada membro da família, priorizando sempre a otimização do espaço e o conforto. A fluidez entre os ambientes e a integração dos espaços sociais reforçam a harmonia do projeto, que combina o clássico com o contemporâneo de maneira impecável.
              <br />
              Este projeto é um exemplo claro de como a arquitetura de interiores pode ser atemporal, moderna e personalizada. A casa reflete, com perfeição, os desejos dos clientes e as soluções criativas do escritório, criando um lar sofisticado, acolhedor e funcional.
            </p>
          </div>
        </div>
      </div>

      <Exposition images={[
        "/assets/galeria/interior_3/Prancheta 1.png",
        "/assets/galeria/interior_3/Prancheta 2.png",
        "/assets/galeria/interior_3/Prancheta 3.png",
        "/assets/galeria/interior_3/Prancheta 4.png",
        "/assets/galeria/interior_3/Prancheta 5.png",
        "/assets/galeria/interior_3/Prancheta 6.png",
      ]} />
      <div className='Curadoria-description'>
        <p className='Typography-title'>
          Apartamento Panorâmico
          <br />
          Cobertura: Conforto e contemporaneidade para uma família alegre e comunicativa
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Este projeto de apartamento cobertura foi concebido para atender às necessidades e ao estilo de vida de uma família alegre, comunicativa e que valoriza tanto o conforto quanto a modernidade. Com dois andares, o design foi pensado para criar um ambiente funcional e acolhedor, onde cada membro da família possa se sentir à vontade e conectado, sem abrir mão da sofisticação e do estilo contemporâneo.
              <br />
              Primeiro Andar: Convívio e Funcionalidade
              <br />
              O primeiro andar é o espaço dedicado ao convívio social da família. A sala de estar, ampla e integrada, é o coração da casa, com grandes janelas que permitem a entrada de luz natural e uma vista deslumbrante da cidade. Os sofás confortáveis e as cores neutras são combinados com detalhes vibrantes, refletindo a alegria e a energia da família. A cozinha aberta para a sala de jantar cria um ambiente fluido, ideal para reuniões informais e jantares em família, com acabamentos de alto padrão e equipamentos de última geração.
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              <strong>Segundo Andar: Privacidade e Conforto</strong>
              <br />
              O segundo andar é o refúgio privado da família, com quartos espaçosos e uma área íntima projetada para proporcionar descanso e relaxamento. A suíte master, com uma vista panorâmica incrível, foi pensada como um verdadeiro oásis de tranquilidade.
              <br />
              <strong>Conceito Contemporâneo</strong>
              <br />
              O projeto aposta em uma estética contemporânea, com linhas retas, acabamentos de materiais naturais e tecnologia integrada para garantir o máximo de conforto e praticidade. Todos os espaços são projetados para promover a interação, com áreas abertas e circulações fluidas.
              <br />
              A cobertura é o reflexo do estilo de vida dessa família: vibrante, conectada e sempre pronta para receber amigos e parentes, sem perder o toque de sofisticação e conforto que caracteriza a sua identidade. Cada detalhe foi cuidadosamente planejado para criar um espaço onde as pessoas possam se sentir bem, com liberdade para conversar, brincar e, acima de tudo, aproveitar o prazer de estar em casa.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

/*
      <Exposition images={[
      ]} />
      <div className='Curadoria-description'>
        <p className='Typography-title'>
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>

            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>

            </p>
          </div>
        </div>
      </div>

*/