import { PageTitle } from '../../components/page-title'
import './comerciais.scss'
import { Exposition } from './exposition'
import { GaleriaHeader } from './galeria-header'

export const Comerciais = () => {
  return (
    <div className='Comerciais'>
      <PageTitle title="Fábia Teixeira | Comerciais" />

      <GaleriaHeader />

      <div className="Comerciais-title">
        Comerciais
      </div>

      <Exposition images={[
        "/assets/galeria/comercial_1/Prancheta 1.png",
        "/assets/galeria/comercial_1/Prancheta 2.png",
        "/assets/galeria/comercial_1/Prancheta 3.png",
        "/assets/galeria/comercial_1/Prancheta 4.png",
        "/assets/galeria/comercial_1/Prancheta 5.png",
      ]} />
      <div className='Comerciais-description'>
        <p className='Typography-title'>
          Projeto Arquitetônico - Espaço de Eventos para Casamentos de Alto Luxo
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Localizado na encantadora região de Colônia Muricy, em São José dos Pinhais, o espaço de eventos projetado pelo escritório Fábia Teixeira Arquitetura foi criado para proporcionar uma experiência única de sofisticação e luxo, ideal para casamentos inesquecíveis. Com um design linear e contemporâneo, a arquitetura se integra perfeitamente ao campo, oferecendo uma conexão fluida com a natureza ao redor.
              <br />
              O uso de materiais modernos, como concreto aparente, vidro e aço corten, imprime um caráter inovador e atemporal ao projeto, ao mesmo tempo que garante durabilidade e elegância. O layout do espaço foi meticulosamente planejado para garantir um fluxo impecável entre os ambientes internos e externos, criando um cenário ideal para cada momento da celebração, com espaços que atendem tanto ao conforto quanto à estética refinada.
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              O salão principal, com seu pé-direito alto e amplas janelas, proporciona uma vista panorâmica deslumbrante do campo, enquanto a iluminação foi projetada para transformar a atmosfera conforme o evento se desenvolve.
              <br />
              Cada detalhe foi pensado para garantir que noivos e convidados vivam uma experiência sensorial única, onde a arquitetura moderna se alia à natureza para criar um ambiente de luxo e exclusividade, ideal para celebrações memoráveis.
            </p>
          </div>
        </div>
      </div>

      <Exposition images={[
        "/assets/galeria/comercial_2/Prancheta 1.png",
        "/assets/galeria/comercial_2/Prancheta 2.png",
        "/assets/galeria/comercial_2/Prancheta 3.png",
        "/assets/galeria/comercial_2/Prancheta 4.png",
        "/assets/galeria/comercial_2/Prancheta 5.png",
        "/assets/galeria/comercial_2/Prancheta 6.png",
      ]} />
      <div className='Comerciais-description'>
        <p className='Typography-title'>
          Projeto complexo de cabelo e estética
          <br />
          Onde a excelência encontra o conforto
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Este projeto foi pensado para criar uma experiência única de transformação, voltada para clientes exigentes e de bom gosto, que buscam um ritual de cuidado, com verdadeira sofisticação.
              <br />
              Contemporâneo e com ambientação de luxo e atmosfera estética imersiva, a funcionalidade
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              se encontra com design arrojado e inovador. A utilização de materiais nobres, acabamentos e iluminação estratégica cria uma atmosfera vibrante na qual beleza e bem-estar estão combinados.
              <br />
              Tanto o projeto Arquitetônico quanto o de Interior Design, proporcionam modernidade e aconchego, reforçando a marca, que é um refúgio de exclusividade e bem-estar.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

/*
      <Exposition images={[
      ]} />
      <div className='Comerciais-description'>
        <p className='Typography-title'>
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>

            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>

            </p>
          </div>
        </div>
      </div>

*/