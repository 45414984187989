
export const Pinterest = () => {
    return (
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="33 33 133.334 133.008" viewBox="33 33 133.334 133.008" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="shape" role="presentation" aria-hidden="true" aria-label="">
            <g>
                <path d="M99.725 33C62.875 33 33 62.75 33 99.45c0 28.167 17.575 52.225 42.392 61.908-.6-5.258-1.1-13.341.216-19.091 1.217-5.192 7.809-33.034 7.809-33.034s-1.975-3.991-1.975-9.841c0-9.242 5.375-16.134 12.075-16.134 5.708 0 8.458 4.267 8.458 9.35 0 5.692-3.625 14.225-5.55 22.159-1.592 6.608 3.35 12.025 9.883 12.025 11.867 0 20.984-12.467 20.984-30.409 0-15.908-11.475-27.016-27.9-27.016-19 0-30.142 14.166-30.142 28.825 0 5.683 2.192 11.808 4.942 15.15.541.658.6 1.258.433 1.908-.492 2.083-1.65 6.625-1.867 7.55-.275 1.208-.983 1.475-2.25.875-8.35-3.883-13.566-15.967-13.566-25.758 0-20.95 15.266-40.2 44.1-40.2 23.125 0 41.133 16.416 41.133 38.4 0 22.908-14.5 41.341-34.6 41.341-6.75 0-13.125-3.5-15.267-7.658 0 0-3.35 12.692-4.175 15.808-1.475 5.8-5.55 13.017-8.291 17.442a67.162 67.162 0 0 0 19.766 2.958c36.85 0 66.726-29.75 66.726-66.45C166.442 62.75 136.575 33 99.725 33Z" fill-rule="evenodd"></path>
            </g>
        </svg>
    )
}
