
export const Instagram = () => {
    return (
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" height="25" width="25" data-type="color" role="img" aria-label="Component 3">
            <g>
                <path d="M16.225 6.654h-7.45a2.166 2.166 0 0 0-2.159 2.16v7.449c0 1.186.972 2.159 2.16 2.159h7.449a2.167 2.167 0 0 0 2.159-2.16V8.814a2.166 2.166 0 0 0-2.16-2.159M12.5 16.401a3.885 3.885 0 0 1-3.876-3.876A3.885 3.885 0 0 1 12.5 8.65a3.885 3.885 0 0 1 3.876 3.876 3.885 3.885 0 0 1-3.876 3.876m4.003-6.944a.92.92 0 0 1-.922-.922.91.91 0 0 1 .922-.921c.517 0 .921.416.921.921a.927.927 0 0 1-.921.922" data-color="1"></path>
                <path d="M12.5 10.303a2.23 2.23 0 0 0-2.235 2.235 2.23 2.23 0 0 0 2.235 2.235 2.23 2.23 0 0 0 2.235-2.235 2.23 2.23 0 0 0-2.235-2.235" data-color="1"></path>
                <path d="M12.5 0C5.593 0 0 5.593 0 12.5S5.593 25 12.5 25 25 19.407 25 12.5 19.407 0 12.5 0m7.525 16.263a3.8 3.8 0 0 1-3.788 3.8h-7.45A3.794 3.794 0 0 1 5 16.263v-7.45a3.79 3.79 0 0 1 3.788-3.788h7.45a3.79 3.79 0 0 1 3.787 3.788z" data-color="1"></path>
            </g>
        </svg>
    )
}
