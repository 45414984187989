
export const Whatsapp = () => {
  return (
    <svg preserveAspectRatio="xMidYMid meet" data-bbox="33 33 133.333 133.333" viewBox="33 33 133.333 133.333" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="shape" role="presentation" aria-hidden="true" aria-label="">
      <g>
        <path d="M130.212 112.901c-1.658-.826-9.814-4.82-11.334-5.371-1.521-.551-2.627-.826-3.733.826-1.105 1.652-4.284 5.371-5.252 6.473-.968 1.101-1.935 1.24-3.594.413-1.659-.826-7.004-2.569-13.34-8.194-4.931-4.377-8.26-9.783-9.228-11.436-.967-1.653-.103-2.546.728-3.37.746-.74 1.659-1.928 2.488-2.892.83-.963 1.106-1.652 1.659-2.753.553-1.103.276-2.067-.139-2.893-.414-.826-3.732-8.952-5.114-12.258-1.347-3.218-2.714-2.782-3.732-2.834a66.765 66.765 0 0 0-3.18-.058c-1.105 0-2.903.413-4.423 2.066-1.52 1.653-5.806 5.647-5.806 13.772 0 8.126 5.944 15.976 6.774 17.078.829 1.102 11.697 17.776 28.337 24.927 3.958 1.701 7.048 2.717 9.456 3.478 3.974 1.257 7.59 1.079 10.448.654 3.187-.474 9.815-3.993 11.197-7.849 1.382-3.857 1.382-7.162.967-7.851-.414-.689-1.52-1.102-3.179-1.928Zm-30.266 41.128h-.023c-9.9-.004-19.612-2.651-28.084-7.654l-2.015-1.191-20.884 5.453 5.574-20.265-1.312-2.078a54.59 54.59 0 0 1-8.436-29.224c.012-30.278 24.765-54.911 55.202-54.911 14.738.005 28.592 5.725 39.01 16.105s16.152 24.177 16.147 38.85c-.013 30.28-24.766 54.915-55.18 54.915Zm46.961-101.653C134.373 39.888 117.704 33.007 99.945 33 63.353 33 33.57 62.637 33.556 99.065a65.711 65.711 0 0 0 8.862 33.031L33 166.333l35.193-9.188a66.57 66.57 0 0 0 31.725 8.042h.027c36.589 0 66.374-29.64 66.388-66.069.007-17.654-6.892-34.254-19.426-46.742Z" fill-rule="evenodd"></path>
      </g>
    </svg>
  )
}
