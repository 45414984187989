import { useEffect, useState } from 'react';
import SwiperClass from 'swiper';
import { Controller } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ArrowRight } from '../../common/arrow_right';
import './exposition.scss';

export type IExposition = {
  images: string[],
  openIndex: number,
}

export const ExpositionModal = ({ images, openIndex }: IExposition) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(openIndex, 0);
    }
  }, [openIndex, swiper])

  return (
    <div className='Exposition Exposition-modal'>
      <div className='Arrow mirror'>
        <button onClick={() => swiper?.slidePrev() || swiper?.slideTo(images.length - 1)}>
          <ArrowRight />
        </button>
      </div>

      <div className='Carousel'>
        <Swiper
          modules={[Controller]}
          onSwiper={setSwiper}
          controller={{ control: swiper }}
        >
          {images.map((image, i) => (
            <SwiperSlide key={`image.${image}.${i}`} virtualIndex={i}>
              <img src={process.env.PUBLIC_URL + image} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className='Arrow'>
        <button onClick={() => swiper?.slideNext() || swiper?.slideTo(0)}>
          <ArrowRight />
        </button>
      </div>
    </div>
  )
}
