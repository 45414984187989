import emailjs from '@emailjs/browser';
import { FormEvent, useState } from "react";
import "./contact-form.scss";
import { ToastContainer, toast } from 'react-toastify';

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = (e: FormEvent<HTMLFormElement>) => {
    console.info("submit", process.env)
    e.persist();
    e.preventDefault();

    if (!e.currentTarget.reportValidity()) {
      return;
    }

    setIsSubmitting(true);

    const target = e.currentTarget;

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID!,
        process.env.REACT_APP_TEMPLATE_ID!,
        e.currentTarget,
        { publicKey: process.env.REACT_APP_PUBLIC_KEY },
      )
      .then(
        (result) => {
          setIsSubmitting(false);
          toast.success("Mensagem enviada com sucesso! Agradecemos seu contato.");
          target.reset();
        },
        (error) => {
          console.error("error", error)
          toast.error("Houve um erro ao enviar sua mensagem, tente novamente ou entre em contato através do email: fabia@teixeira.arq.br")
          setIsSubmitting(false);
        }
      );
  };

  return (
    <form onSubmit={sendEmail} className="ContactForm">
      <div className="ContactForm-row">
        <div className="ContactForm-field">
          <legend>Nome / Sobrenome</legend>
          <input type="text" name="nome" placeholder="Nome" required />
        </div>
        <div className="ContactForm-field">
          <input type="text" name="sobrenome" placeholder="Sobrenome" />
        </div>
      </div>

      <div className="ContactForm-row">
        <div className="ContactForm-field">
          <legend>Email</legend>
          <input type="email" name="email" placeholder="Email" required />
        </div>
      </div>

      <div className="ContactForm-row">
        <div className="ContactForm-field">
          <legend>Telefone / Whatsapp</legend>
          <input type="text" name="telefone" placeholder="### ##### ####" />
        </div>
      </div>

      <div className="ContactForm-row">
        <div className="ContactForm-field">
          <legend>Endereço</legend>
          <input type="text" name="endereco_linha1" placeholder="Endereço" required />
        </div>
      </div>

      <div className="ContactForm-row">
        <div className="ContactForm-field">
          <input type="text" name="endereco_linha2" placeholder="Endereço Linha 2" />
        </div>
      </div>

      <div className="ContactForm-row">
        <div className="ContactForm-field">
          <input type="text" name="endereco_cidade" placeholder="Cidade" />
        </div>
        <div className="ContactForm-field">
          <input type="text" name="endereco_estado" placeholder="Estado" />
        </div>
      </div>

      <div className="ContactForm-row">
        <div className="ContactForm-field">
          <input type="text" name="endereco_cep" placeholder="CEP" />
        </div>
        <div className="ContactForm-field">
          <input type="text" name="endereco_pais" placeholder="País" />
        </div>
      </div>

      <div className="ContactForm-row">
        <div className="ContactForm-field">
          <legend>Mensagem</legend>
          <textarea name="mensagem" placeholder="Conte-nos algo" />
        </div>
      </div>

      <div className="ContactForm-row">
        <div className="ContactForm-field">
          <input type="submit" value="Enviar Formulário" disabled={isSubmitting} />
        </div>
      </div>
    </form>
  );
}; export default ContactForm;