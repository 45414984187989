import { BrowserRouter, Outlet, Route, Routes } from 'react-router';
import './App.scss';
import { SiteLayout } from './components/site-layout';
import { ContatoPage } from './pages/contato';
import { DnaPage } from './pages/dna';
import { Apartamentos } from './pages/galeria/apartamentos';
import { Casas } from './pages/galeria/casas';
import { Comerciais } from './pages/galeria/comerciais';
import { Curadoria } from './pages/galeria/curadoria';
import { GaleriaPage } from './pages/galeria/galeria';
import { IndexPage } from './pages/index';
import { SolucoesPage } from './pages/solucoes';

import "swiper/css";
import "swiper/css/controller";
import "swiper/css/autoplay";
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className="MainSite">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<SiteLayout />}>
            <Route path='/' element={<IndexPage />} />
            <Route path='/dna-ft' element={<DnaPage />} />
            <Route path='/galeria' element={<Outlet />}>
              <Route path='/galeria' element={<GaleriaPage />} />
              <Route path='/galeria/casas' element={<Casas />} />
              <Route path='/galeria/apartamentos' element={<Apartamentos />} />
              <Route path='/galeria/curadoria' element={<Curadoria />} />
              <Route path='/galeria/comerciais' element={<Comerciais />} />
            </Route>
            <Route path='/solucoes' element={<SolucoesPage />} />
            <Route path='/contato' element={<ContatoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
