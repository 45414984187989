import { PageTitle } from '../../components/page-title'
import './apartamentos.scss'
import { Exposition } from './exposition'
import { GaleriaHeader } from './galeria-header'

export const Apartamentos = () => {
  return (
    <div className='Apartamentos'>
      <PageTitle title="Fábia Teixeira | Apartamentos" />

      <GaleriaHeader />

      <div className="Apartamentos-title">
        Apartamentos
      </div>

      <Exposition images={[
        "/assets/galeria/apt_1/Prancheta 1.png",
        "/assets/galeria/apt_1/Prancheta 2.png",
        "/assets/galeria/apt_1/Prancheta 3.png",
        "/assets/galeria/apt_1/Prancheta 4.png",
        "/assets/galeria/apt_1/Prancheta 5.png",
        "/assets/galeria/apt_1/Prancheta 6.png",
      ]} />
      <div className='Apartamentos-description'>
        <p className='Typography-title'>
          Apartamento Essência
          <br />
          Elegância contemporânea e personalidade única
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Quando a arquiteta Fábia Teixeira foi contratada para projetar o apartamento de um cliente em uma das regiões mais nobres de Curitiba, ela recebeu uma tela em branco: o desafio de transformar um espaço vazio em um lar que fosse, ao mesmo tempo, prático, funcional e profundamente personalizado. O desejo do cliente era claro: criar um ambiente que refletisse sua personalidade única, utilizando uma paleta de tons sóbrios, predominando cinza e preto, com linhas contemporâneas e limpas, mas que mantivesse uma atmosfera acolhedora, longe da frieza habitual dos ambientes minimalistas.
              <br />
              Com sua sensibilidade refinada, Fábia soube, com maestria, equilibrar os elementos essenciais para criar um ambiente sofisticado sem perder a calorosidade. Cada escolha de material, mobiliário, e tecido foi cuidadosamente pensada para orquestrar nuances delicadas e ao mesmo tempo impactantes, garantindo um espaço elegante e moderno, mas com o aconchego necessário para um lar. Os tons neutros, como cinza e preto, foram usados de maneira a criar profundidade e sofisticação, mas sempre acompanhados de texturas que suavizam a estética, evitando qualquer sensação de dureza.
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              A arquitetura de interiores se desenhou em torno da harmonia e da funcionalidade, com um design clean e minimalista que reflete a personalidade do cliente, mas com atenção aos detalhes que tornam o ambiente agradável e acolhedor. Mobiliários sob medida, peças de design contemporâneo e acabamentos luxuosos, como pedras e madeiras nobres, foram cuidadosamente integrados ao projeto, criando uma atmosfera de requinte sem excessos.
              <br />
              Os quartos dos filhos foram projetados com o mesmo cuidado e personalização, refletindo suas personalidades e ao mesmo tempo otimizando ao máximo os espaços. Cada ambiente foi pensado de forma inteligente e prática, sem abrir mão do conforto e da elegância, criando um espaço único para cada membro da família. O resultado é um apartamento que, além de sofisticado, oferece funcionalidade e bem-estar, atendendo às necessidades de uma vida moderna e dinâmica.
              <br />
              O apartamento Sosa é um exemplo de como a arquitetura contemporânea pode ser ao mesmo tempo minimalista e acolhedora, onde cada detalhe é pensado para proporcionar uma experiência de vida luxuosa, prática e única. O projeto traduz não apenas o estilo de vida do cliente, mas também a busca por um lar que se transforma em um verdadeiro reflexo da sua personalidade.
            </p>
          </div>
        </div>
      </div>

      <Exposition images={[
        "/assets/galeria/apt_2/Prancheta 1.png",
        "/assets/galeria/apt_2/Prancheta 2.png",
        "/assets/galeria/apt_2/Prancheta 3.png",
        "/assets/galeria/apt_2/Prancheta 4.png",
        "/assets/galeria/apt_2/Prancheta 5.png",
        "/assets/galeria/apt_2/Prancheta 6.png",
      ]} />
      <div className='Apartamentos-description'>
        <p className='Typography-title'>
          Apartamento Convívio
          <br />
          Elegância contemporânea e personalidade única
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              O escritório Fábia Teixeira Arquitetura desenvolveu um projeto de interiores para uma família de bom gosto que reside em um bairro nobre de Curitiba, com foco na sofisticação, funcionalidade e conforto. O objetivo foi criar um ambiente que favorece o convívio e o lazer nos fins de semana, quando a família se reúne para momentos de descontração e celebração.A solução adotada foi a integração fluida entre os ambientes sociais, utilizando painéis de madeira e acabamentos em tons neutros para criar um espaço único e amplo, sem perder a elegância.
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Esses materiais foram escolhidos não apenas por sua estética refinada, mas também pela capacidade de conectar as salas de estar, jantar e a área gourmet de forma prática e harmônica.
              <br />
              Em resumo, o projeto traduz a personalidade refinada dos moradores, oferecendo um ambiente moderno e funcional para a convivência familiar e momentos de lazer.
            </p>
          </div>
        </div>
      </div>


      <Exposition images={[
        "/assets/galeria/apt_3/Prancheta 1.png",
        "/assets/galeria/apt_3/Prancheta 2.png",
        "/assets/galeria/apt_3/Prancheta 3.png",
        "/assets/galeria/apt_3/Prancheta 4.png",
        "/assets/galeria/apt_3/Prancheta 5.png",
        "/assets/galeria/apt_3/Prancheta 6.png",
        "/assets/galeria/apt_3/Prancheta 7.png",
        "/assets/galeria/apt_3/Prancheta 8.png",
      ]} />
      <div className='Apartamentos-description'>
        <p className='Typography-title'>
          Apartamento Refúgio
          <br />
          Elegância contemporânea e personalidade única
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Quando uma família de Campinas buscou a arquiteta Fábia Teixeira para criar um refúgio em Curitiba, o objetivo era desenvolver um espaço que não apenas atendesse às suas necessidades práticas, mas também refletisse sua essência — uma família distinta, com carreiras brilhantes e um gosto refinado por viagens, cultura, culinária e línguas. Para concretizar essa visão, a arquiteta mergulhou no universo desses clientes, trazendo à tona suas preferências e criando um ambiente que traduzisse sua personalidade com sensibilidade e sofisticação.
              <br />
              O apartamento foi concebido como um verdadeiro reflexo da elegância e modernidade da família, sendo ao mesmo tempo acolhedor e intimista, perfeito para momentos de lazer e convivência. A seleção cuidadosa de mobiliário, cores, iluminação e materiais foi feita para garantir um equilíbrio entre a estética contemporânea e a funcionalidade. Soluções inovadoras de marcenaria e o uso de texturas nobres criaram uma atmosfera sofisticada, enquanto o uso de tons neutros e toques sutis de cor conferiram calor e aconchego aos espaços.
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Cada ambiente do apartamento é pensado para ser atemporal, com elementos de design que, além de belos, permanecem relevantes e elegantes ao longo do tempo. A escolha de materiais refinados e a iluminação estrategicamente posicionada destacam os pontos fortes de cada ambiente, criando uma fluidez de espaços que estimulam o conforto e a convivência.
              <br />
              O projeto, em sua totalidade, reflete o desejo da família de ter um lar onde o luxo se faz presente sem ostentação, e onde cada detalhe, desde a marcenaria sob medida até a seleção de móveis e objetos, conta uma história de bom gosto e sofisticação. O resultado final é um apartamento que não apenas atende às expectativas dos moradores, mas eleva o conceito de elegância e funcionalidade para um novo patamar, criando um espaço verdadeiramente atemporal.
            </p>
          </div>
        </div>
      </div>


    </div>
  )
}

/*
      <Exposition images={[
      ]} />
      <div className='Apartamentos-description'>
        <p className='Typography-title'>
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>

            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>

            </p>
          </div>
        </div>
      </div>

*/