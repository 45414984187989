import { Link } from "react-router";
import { ArrowDown } from "../common/arrow_down";
import { Instagram } from "../common/instagram";
import { Pinterest } from "../common/pinterest";
import "./index.scss";
import { PageTitle } from "../components/page-title";

export const IndexPage = () => {
  return (
    <div className="IndexPage">
      <PageTitle title="Fábia Teixeira | Arquitetura" />
      <div className="IndexPage-banner">
        <img
          className="banner"
          src={process.env.PUBLIC_URL + '/assets/banner_home.avif'} />
        <img
          className="banner-logo"
          src={process.env.PUBLIC_URL + '/assets/logo_vertical.avif'} />
      </div>

      <div className="IndexPage-navigation">
        <nav>
          <div className="spacer" />
          <Link to="/galeria">Galeria Completa</Link>
          <Link to="/galeria/casas">Casas</Link>
          <Link to="/galeria/apartamentos">Apartamentos</Link>
          <Link to="/galeria/curadoria">Curadoria De Interior Design</Link>
          <Link to="/galeria/comerciais">Comerciais</Link>
          <div className="spacer" />
        </nav>
      </div>

      <div className="Section Layout-row">
        <div className="Layout-20 Section-text">
          <h2 className="Typography-title">
            DNA
            <br />
            FÁBIA TEIXEIRA
          </h2>

          <p className="Typography-body">
            Paixão por criar projetos contemporâneos com foco na excelência.
            <br />
            O DNA do escritório Fabia Teixeira Arquitetura é a união entre sofisticação, funcionalidade e personalização. Cada projeto é uma expressão única do estilo de vida e das aspirações dos clientes.
          </p>

          <div className="Section-social">
            <a href="https://www.instagram.com/fabiateixeiraarquitetura/">
              <Instagram />
            </a>
            <a href="https://br.pinterest.com/FABIATEIXEIRAARQUITETURA/">
              <Pinterest />
            </a>
          </div>

          <div className="Section-dna">
            <Link to="/dna-ft" className="underline">
              SAIBA MAIS <ArrowDown />
            </Link>
          </div>
        </div>

        <div className="Layout-80 Section-image">
          <img src={process.env.PUBLIC_URL + '/assets/banner_home_2.avif'} />
          <div className="floating-description">
            FÁBIA TEIXEIRA
            <br />
            Arquiteta - Founder
          </div>
        </div>
      </div>

      <div className="Section Layout-row">
        <div className="Layout-20 Section-text">
          <h2 className="Typography-title">
            Paixão por criar projetos contemporâneos com foco na excelência
          </h2>

          <p className="Typography-body">
            Criamos projetos Arquitetônicos e Curadoria de Interiores para os clientes mais exigentes, superamos seus objetivos e tornamos sua vida melhor.
          </p>

          <div className="Section-casas">
            <Link to="/galeria/casas" className="underline">
              SAIBA MAIS <ArrowDown />
            </Link>
          </div>
        </div>

        <div className="Layout-80 Section-image">
          <img src={process.env.PUBLIC_URL + '/assets/Prancheta_1.avif'} />
        </div>
      </div>

      <div className="Section Layout-row">
        <div className="Layout-80 Section-image">
          <img src={process.env.PUBLIC_URL + '/assets/Prancheta_2.avif'} />
        </div>

        <div className="Layout-20 Section-text">
          <h2 className="Typography-title">
            Uma busca pela excelência e
            <br />
            satisfação absoluta do cliente
          </h2>

          <p className="Typography-body">
            Os melhores resultados só podem ser alcançados por meio da combinação mais precisa dos profissionais adequados ao estilo e tamanho de cada projeto.
            <br />
            No Escritório Fábia Teixeira, adaptamos nossa expertise para criar uma abordagem coordenada, especializada e de ponto único. Dessa forma, maximizamos a eficiência, a qualidade e o valor de cada projeto em termos de investimento, design e construção.
          </p>

          <div className="Section-solucoes">
            <Link to="/solucoes" className="underline">
              SAIBA MAIS <ArrowDown />
            </Link>
          </div>
        </div>
      </div>

      <div className="Section Layout-row">
        <div className="Layout-40 Section-image">
          <img src={process.env.PUBLIC_URL + '/assets/banner_home_3.avif'} />
        </div>

        <div className="Layout-60 Section-text Section-about">
          <h2 className="Typography-title">
            SOBRE
          </h2>

          <p className="Typography-body">
            Temos paixão por criar projetos contemporâneos que se concentrem em nossa visão de excelência, entendida como a qualidade do espaço, a precisão e a beleza.
          </p>

          <h2 className="Typography-title">
            Architecture & Design Services
          </h2>

          <p className="Typography-body">
            Criamos um processo próximo e personalizado que leva em consideração uma experiência de cliente excepcional e sem complicações, por meio da combinação dos especialistas necessários em cada caso. garantimos uma abordagem coordenada, maior atenção aos detalhes.
          </p>

          <h2 className="Typography-title">
            Equipe Fábia Teixeira Arquitetura
          </h2>

          <p className="Typography-body">
            Nossa equipe é composta por um grupo multidisciplinar de profissionais que priorizam colocar as necessidades dos clientes no centro do que fazemos.
          </p>
        </div>
      </div>

    </div>
  )
}
