import { Link } from 'react-router'
import './galeria.scss'
import { ArrowDown } from '../../common/arrow_down'
import { PageTitle } from '../../components/page-title'

export const GaleriaPage = () => {
  return (
    <div className='GaleriaPage'>
      <PageTitle title="Fábia Teixeira | Galeria Completa" />

      <div className='Galeria-navigation'>
        <nav>
          <div className='nav-link'>
            <span>Casas</span>
            <Link to="/galeria/casas" className='underline'>
              Saiba Mais <ArrowDown />
            </Link>
          </div>
          <div className='nav-link'>
            <span>Apartamentos</span>
            <Link to="/galeria/apartamentos" className='underline'>
              Saiba Mais <ArrowDown />
            </Link>
          </div>
          <div className='nav-link'>
            <span>Curadoria De Design</span>
            <Link to="/galeria/curadoria" className='underline'>
              Saiba Mais <ArrowDown />
            </Link>
          </div>
          <div className='nav-link'>
            <span>Comerciais</span>
            <Link to="/galeria/comerciais" className='underline'>
              Saiba Mais <ArrowDown />
            </Link>
          </div>
        </nav>
      </div>

      {/*  */}

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Casa Encontro | Pinhais
          </h2>

          <p className="Typography-body">
            Um exemplar de sofisticação e funcionalidade, projetada para oferecer exclusividade e conforto.
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_1.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Casa Cenário Alphaville Graciosa | Pinhais
          </h2>

          <p className="Typography-body">
            Arquitetura Contemporânea e Conforto em Harmonia com o Terreno
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_2.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Casa Platôs
          </h2>

          <p className="Typography-body">
            Arquitetura contemporânea e sofisticação em terreno desafiador
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_3.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Casa Reserva
          </h2>

          <p className="Typography-body">
            Arquitetura Imponente e Conforto Atemporal
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_4.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Casa Alma
          </h2>

          <p className="Typography-body">
            Elegância, história e sofisticação no coração de Curitiba
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_5.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Casa Oásis
          </h2>

          <p className="Typography-body">
            Arquitetura contemporânea e harmonia com a natureza em Quatro Barras
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_6.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Casa Horizonte | Campo Largo
          </h2>

          <p className="Typography-body">
            Campo Largo
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_7.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Casa Raízes
          </h2>

          <p className="Typography-body">
            Conforto e funcionalidade para família em crescimento
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_8.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Casa Delta
          </h2>

          <p className="Typography-body">
            Projeto Residencial no Parque Barigui - Curitiba
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_9.avif'} />
        </div>
      </div>


      {/*  */}


      <div className="Galeria-separator separator-2">
        APARTAMENTOS
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Apartamento Refúgio
          </h2>

          <p className="Typography-body">
            O apartamento foi concebido como um verdadeiro reflexo da elegância e modernidade da família, sendo ao mesmo tempo acolhedor e intimista
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_10.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Apartamento Essência
          </h2>

          <p className="Typography-body">
            O apartamento foi concebido como um verdadeiro reflexo da elegância e modernidade da família, sendo ao mesmo tempo acolhedor e intimista
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_11.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Apartamento Convívio
          </h2>

          <p className="Typography-body">
            O apartamento foi concebido como um verdadeiro reflexo da elegância e modernidade da família, sendo ao mesmo tempo acolhedor e intimista
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_12.avif'} />
        </div>
      </div>

      {/*  */}


      <div className="Galeria-separator separator-3">
        CURADORIA INTERIOR DESIGN
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Casa Reserva
          </h2>

          <p className="Typography-body">
            Arquitetura imponente e conforto atemporal
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_13.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Casa Equilíbrio
          </h2>

          <p className="Typography-body">
            Elegância clássica e contemporânea para família
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_14.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Apartamento Panorâmico
          </h2>

          <p className="Typography-body">
            Elegância clássica e contemporânea para família
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_15.avif'} />
        </div>
      </div>

      {/*  */}


      <div className="Galeria-separator separator-4">
        COMERCIAIS
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Espaço de eventos para Casamentos de Alto Luxo
          </h2>

          <p className="Typography-body">
            Projeto pensado para proporcionar uma experiência única de sofisticação e luxo, ideal para casamentos inesquecíveis.
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_16.avif'} />
        </div>
      </div>

      <div className='Galeria-showcase Layout-row'>
        <div className='Section-text Layout-20'>
          <h2 className="Typography-title">
            Projeto complexo de cabelo e estética
          </h2>

          <p className="Typography-body">
            Onde a excelência encontra o conforto
          </p>
        </div>

        <div className='Section-image Layout-80'>
          <img src={process.env.PUBLIC_URL + '/assets/galeria_17.avif'} />
        </div>
      </div>

    </div>
  )
}
