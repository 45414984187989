import { Link } from 'react-router'
import { ArrowDown } from '../../common/arrow_down'
import "./galeria-header.scss";

export const GaleriaHeader = () => {
  return (
    <div className="GaleriaHeader">
      <nav>
        <div className='nav-link'>
          <Link to="/galeria" className='underline'>
            Galeria Completa <ArrowDown />
          </Link>
        </div>
        <div className='nav-link'>
          <Link to="/galeria/casas" className='underline'>
            Casas <ArrowDown />
          </Link>
        </div>
        <div className='nav-link'>
          <Link to="/galeria/apartamentos" className='underline'>
            Apartamentos <ArrowDown />
          </Link>
        </div>
        <div className='nav-link'>
          <Link to="/galeria/curadoria" className='underline'>
            Curadoria De Design <ArrowDown />
          </Link>
        </div>
        <div className='nav-link'>
          <Link to="/galeria/comerciais" className='underline'>
            Comerciais <ArrowDown />
          </Link>
        </div>
      </nav>
    </div>
  )
}
