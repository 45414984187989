import { useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Modal from 'react-modal';
import SwiperClass from 'swiper';
import { Autoplay, Controller } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ArrowRight } from '../../common/arrow_right';
import { Close } from '../../common/close';
import { ExpositionModal } from './exposition-modal';
import './exposition.scss';
import { ArrowDown } from '../../common/arrow_down';

export type IExposition = {
  images: string[],
}

export const Exposition = ({ images }: IExposition) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [openImage, setOpenImage] = useState<number | null>(null);
  const handle = useFullScreenHandle();

  return (
    <div className='Exposition'>
      <div className='Arrow mirror'>
        <button onClick={() => swiper?.slidePrev() || swiper?.slideTo(images.length - 1)}>
          <ArrowRight />
        </button>
      </div>

      <div className='Carousel'>
        <Swiper
          modules={[Controller, Autoplay]}
          onSwiper={setSwiper}
          controller={{ control: swiper }}
          autoplay
        >
          {images.map((image, i) => (
            <SwiperSlide key={`image.${image}.${i}`} virtualIndex={i}>
              <img
                src={process.env.PUBLIC_URL + image}
                className='hover'
                onClick={() => setOpenImage(i)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className='Arrow'>
        <button onClick={() => swiper?.slideNext() || swiper?.slideTo(0)}>
          <ArrowRight />
        </button>
      </div>

      {typeof openImage === "number" &&
        <Modal
          isOpen={typeof openImage === "number"}
          style={{
            overlay: {
              zIndex: 100,
            },
            content: {
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              background: "#fff",
              padding: 0,
            },
          }}
        >
          <FullScreen handle={handle}>
            <div className="Modal-content">
              <div
                className="Modal-close"
                onClick={() => {
                  if (handle.active) {
                    handle.exit();
                  } else {
                    setOpenImage(null);
                  }
                }}
              >
                <Close />
              </div>

              <div
                className={`Modal-fullscreen ${handle.active ? "Modal-active" : ""}`}
                onClick={handle.active ? handle.exit : handle.enter}
              >
                <ArrowDown />
                <ArrowDown />
              </div>

              <ExpositionModal images={images} openIndex={openImage} />
            </div>
          </FullScreen>
        </Modal>}
    </div>
  )
}
