import { PageTitle } from "../components/page-title"
import "./solucoes.scss"

export const SolucoesPage = () => {
  return (
    <div className='SolucoesPage'>
      <PageTitle title="Fábia Teixeira | Soluções" />

      <div className="Section Layout-row">
        <div className="Layout-50 Section-text">
          <h2 className="Typography-title">
            Alcançamos os melhores resultados por meio da combinação correta de profissionais especialistas.
          </h2>

          <div>
            <h3 style={{ fontWeight: "bold" }}>
              NOSSAS SOLUÇÕES
            </h3>

            <p className="Typography-body">
              Arquitetura | Projetos Arquitetônicos
              <br />
              Consultoria para aquisição de terrenos
              <br />
              Curadoria especializada em Interior Design
              <br />
              Gestão de construção
              <br />
              Especificações do Projeto
            </p>
          </div>
        </div>

        <div className="Layout-50 Section-image">
          <img src={process.env.PUBLIC_URL + '/assets/solucoes_1.avif'} />
        </div>
      </div>

      <div className="LargeImage">
        <img src={process.env.PUBLIC_URL + '/assets/solucoes_2.avif'} className="background" />
        <img src={process.env.PUBLIC_URL + '/assets/solucoes_vertical.avif'} className="overlay" />
        <div className="overlay-text">
          Design
          <br />
          além da
          <br />
          estética
        </div>
        <div className="overlay-body">
          <div className="overlay-body-title">
            Design
            <br />
            além da
            <br />
            estética
          </div>
          <p>
            O sucesso é alcançado por meio da interação eficiente e da interdependência de um grande número de especialistas, serviços e provedores que dependem intimamente uns dos outros.
            <br />
            <br />
            O Escritório Fábia Teixeira Arquitetura adapta nossa expertise para criar uma abordagem coordenada e centralizada que facilita a comunicação com o cliente, maximizamos o desempenho do processo e garantimos o sucesso dos resultados .
            <br />
            <br />
            Fazemos projetos personalizados e uma curadoria design de interiores além de muitos outros serviços, podemos oferecer um serviço especializado individual ou um serviço completo que abrange projetos do início ao fim (soluções prontas para uso) para atender a qualquer necessidade específica.
          </p>
        </div>
      </div>

      <div className="Image">
        <img src={process.env.PUBLIC_URL + '/assets/solucoes_3.avif'} />
      </div>

      <div className="Section Layout-row" style={{ minHeight: 660, alignItems: "start", justifyContent: "space-evenly" }}>
        <div className="Layout-30 Section-text">
          <h2 className="Typography-title" style={{ height: 140 }}>
            Arquitetura | Projetos Arquitetônicos
          </h2>

          <p className="Typography-body">
            O escritório Fábia Teixeira Arquitetura se destaca pela criação de projetos que unem arte refinada e ciência meticulosa, com o objetivo de impactar positivamente o estilo de vida das pessoas e os resultados dos negócios.
            <br />
            <br />
            Nossa abordagem é única e personalizada para cada cliente, sempre buscamos a harmonia entre beleza, funcionalidade e significado. Colocamos as pessoas no centro de cada projeto, criamos espaços que refletem sua identidade e atendem às suas necessidades de forma inovadora.
            <br />
            <br />
            Nosso compromisso é traduzir desejos em soluções arquitetônicas que superem expectativas, seja para clientes residenciais ou corporativos, sempre fiéis à nossa filosofia de design.
          </p>
        </div>

        <div className="Layout-30 Section-text">
          <h2 className="Typography-title" style={{ height: 140 }}>
            Curadoria especializada em Interior Design
          </h2>

          <p className="Typography-body">
            Consideramos que o design arquitetônico e o design de interiores são complementares, um é consequência do outro, por isso nossa equipe inclui igualmente arquitetos e designers de interiores, garantindo não apenas o apelo funcional e estético, mas também a resolução técnica de qualquer detalhe
            <br />
            <br />
            Acreditamos em fornecer uma curadoria de designs de interiores personalizados que tenham como alvo quem é o cliente, desenvolvemos sempre uma leitura com valor estético e funcional focado especificamente em seu estilo de vida e maneira de viver.
          </p>
        </div>

        <div className="Layout-30 Section-text">
          <h2 className="Typography-title" style={{ height: 140 }}>
            Consultoria para aquisição de terrenos
          </h2>

          <p className="Typography-body">
            Escolher o lote ideal para a construção da casa dos sonhos é uma etapa crucial para quem inicia o processo de projeto e construção.
            <br />
            <br />
            O escritório Fábia Teixeira Arquitetura oferece consultoria especializada, guiando seus clientes com um briefing detalhado e assertivo na seleção do terreno. Por meio de uma análise cuidadosa das necessidades e desejos dos futuros moradores, além de uma avaliação técnica do lote, garantimos que o terreno seja perfeitamente compatível com o projeto ideal, assegurando que cada detalhe atenda às expectativas e ao sonho dos clientes.
          </p>
        </div>
      </div>

      <div className="Image">
        <img src={process.env.PUBLIC_URL + '/assets/solucoes_4.avif'} />
      </div>

      <div className="Section Layout-row" style={{ minHeight: 660, alignItems: "start", justifyContent: "space-evenly" }}>
        <div className="Layout-30 Section-text">
          <h2 className="Typography-title" style={{ height: 140 }}>
            Análise Financeira Estratégica
          </h2>

          <p className="Typography-body">
            Por meio de uma  estratégica financeira é possível ter a visibilidade  que cada etapa do seu projeto seja conduzida com máxima transparência e precisão orçamentária, assegurando que sua visão se concretize sem surpresas no orçamento.
            <br />
            <br />
            Por meio de uma análise detalhada de custos e um planejamento rigoroso, nosso processo garante que o projeto seja desenvolvido dentro do orçamento aprovado, sem estouros inesperados. Desde a concepção inicial até a execução, cada escolha é pautada por viabilidade financeira e otimização de recursos, proporcionando total controle sobre o investimento.
            <br />
            <br />
            Com nosso compromisso com eficiência e responsabilidade, você tem a tranquilidade de que seu projeto será entregue conforme o planejado, com qualidade, prazo e custos sob controle.
          </p>
        </div>

        <div className="Layout-30 Section-text">
          <h2 className="Typography-title" style={{ height: 140 }}>
            Gestão de construção
          </h2>

          <p className="Typography-body">
            Para atingir a excelência não apenas no design, mas também na construção, o Escritório FT conta com a gestão de construção especializados que supervisionam o prazo de entrega de qualquer projeto, seu custo e sua qualidade (o triângulo do sucesso), garantimos o desempenho de todo o processo de construção.
          </p>
        </div>

        <div className="Layout-30 Section-text">
          <h2 className="Typography-title" style={{ height: 140 }}>
            Especificações do Projeto
          </h2>

          <p className="Typography-body">
            Consideramos cada detalhe que reflete a personalidade dos nossos clientes, como móveis soltos e embutidos, iluminação decorativa, têxteis como cortinas, tapetes e roupas de cama, mesa e banho, além de eletrodomésticos, talheres, louças e copos. Também cuidamos da especificação de revestimentos, louças e metais, além de objetos e elementos de decoração, arte e artefatos.
            <br />
            <br />
            Adicionalmente, oferecemos uma gama de especialidades para atender às solicitações mais exigentes, como sistemas de casas inteligentes, eficiência energética, segurança privada, integração de áudio e vídeo, equipamentos esportivos, áreas de bem-estar, adegas, home cinema e muito mais.
          </p>
        </div>
      </div>

    </div>
  )
}
