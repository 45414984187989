import React from 'react'

export const ArrowDown = () => {
    return (
        <svg preserveAspectRatio="xMidYMid meet" data-bbox="0.99 0.92 20.16 20.16" xmlns="http://www.w3.org/2000/svg" viewBox="0.99 0.92 20.16 20.16" height="22" width="22" data-type="color" role="img" aria-label="Component 3">
            <g>
                <path d="M21.15 19.69H.99v1.39h20.16z" data-color="1"></path>
                <path d="M19.76.92v20.16h1.39V.92z" data-color="1"></path>
                <path d="M20.652 19.6 1.972.92l-.982.983 18.679 18.68z" data-color="1"></path>
            </g>
        </svg>
    )
}
