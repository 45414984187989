
export const ArrowRight = () => {
  return (
    <svg style={{ transform: "scaleX(1) scale(0.520833);" }} width="40px" height="33px" viewBox="0 0 40 33" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" stroke-width="1" fill-rule="evenodd">
        <g transform="translate(-28.000000, -32.000000)" stroke-width="nonzero">
          <polygon transform="translate(47.800000, 48.469489) rotate(-180.000000) translate(-47.800000, -48.469489) " points="43.2916087 32 44.4061622 33.1660321 30.8430707 47.6817192 67.6 47.6813756 67.6 49.2576026 30.9452714 49.2580761 44.4061622 63.772946 43.2924258 64.9389782 28.8681974 49.4277178 28 48.5197421">
          </polygon>
        </g>
      </g>
    </svg>
  )
}
