import { motion } from "motion/react";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router";
import { Popover } from 'react-tiny-popover';

export const Header = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isPopoverOpen) {
      setIsOpen(true);
    } else {
      setTimeout(() => {
        setIsOpen(false);
      }, 300);
    }
  }, [isPopoverOpen]);

  const onLinkClick = useCallback(() => {
    setIsPopoverOpen(false);
  }, [setIsPopoverOpen]);

  return (
    <header>
      <div className="Header-logo-container">
        <Link to="/" onClick={onLinkClick}>
          <img src={process.env.PUBLIC_URL + '/assets/logo.png'} />
        </Link>
      </div>


      <Popover
        isOpen={isOpen}
        onClickOutside={() => setIsPopoverOpen(false)}
        positions={["bottom"]}
        align="end"
        content={
          <motion.nav
            className="Header-popover"
            initial={{ marginTop: -300 }}
            animate={{ marginTop: isPopoverOpen ? 30 : -300 }}
            transition={{
              duration: 0.3,
              scale: { ease: "easeIn" },
            }}
          >
            <Link onClick={onLinkClick} to="/dna-ft">DNA FT</Link>
            <Link onClick={onLinkClick} to="/galeria">Galeria Completa</Link>
            <Link onClick={onLinkClick} to="/solucoes">Soluções</Link>
            <Link onClick={onLinkClick} to="/contato">Contato</Link>
          </motion.nav>
        }
      >
        <div
          className="burger-menu"
          onClick={() => setIsPopoverOpen(v => !v)}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </Popover>

      <nav className="Header-navigation">
        <Link onClick={onLinkClick} to="/dna-ft">DNA FT</Link>
        <Link onClick={onLinkClick} to="/galeria">Galeria Completa</Link>
        <Link onClick={onLinkClick} to="/solucoes">Soluções</Link>
        <Link onClick={onLinkClick} to="/contato">Contato</Link>
      </nav>
    </header>
  )
}
