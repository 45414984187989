import { Outlet, useLocation } from "react-router"
import { Footer } from "./footer"
import { Header } from "./header"
import { useLayoutEffect } from "react";

export const SiteLayout = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [location.pathname]);

  return (
    <div className="MainSite-layout">
      <Header />
      <div className="MainSite-content">
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}