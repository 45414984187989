import { PageTitle } from '../../components/page-title'
import './casas.scss'
import { Exposition } from './exposition'
import { GaleriaHeader } from './galeria-header'

export const Casas = () => {
  return (
    <div className='Casas'>
      <PageTitle title="Fábia Teixeira | Casas" />

      <GaleriaHeader />

      <div className="Casas-title">
        Casas
      </div>

      <Exposition images={[
        "/assets/galeria/casa_1/Prancheta 1.png",
        "/assets/galeria/casa_1/Prancheta 2.png",
        "/assets/galeria/casa_1/Prancheta 3.png",
      ]} />
      <div className='Casas-description'>
        <p className='Typography-title'>Casa Encontro | Pinhais</p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Situada no prestigiado condomínio Alphaville Graciosa, em Pinhais, a Casa Encontro é um exemplar de sofisticação e funcionalidade, projetada para oferecer exclusividade e conforto. Com uma arquitetura contemporânea e atemporal, o projeto integra ambientes de forma fluida, preservando ao mesmo tempo a privacidade da família. Fachadas equilibradas garantem uma excelente iluminação e ventilação sem comprometer o aconchego dos espaços.
              <br />
              Distribuída em três pavimentos, a residência possui um programa extenso. No andar superior, seis suítes, incluindo uma master com vista panorâmica, closet espaçoso e banheiro estilo spa. Outras três suítes aproveitam a insolação e a vista para a piscina, enquanto duas suítes de hóspedes estão posicionadas estrategicamente para garantir tranquilidade e luz da tarde.
              <br />
              O térreo é dedicado às áreas sociais, com salas de estar e jantar abertas para o bosque frontal, além de uma área de lazer completa com churrasqueira gourmet, piscina com cascata, sauna e mesa de sinuca.
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              O setor de serviços, com lavanderia, despensa e banheiro externo, foi cuidadosamente posicionado para garantir discrição e funcionalidade.
              <br />
              Uma escada monumental e um elevador conectam os pavimentos, proporcionando acessibilidade e fluidez. No subsolo, encontram-se quatro vagas de garagem, uma adega e um espaço de apoio.
              <br />
              Tecnologias sustentáveis, como automação, reaproveitamento de água da chuva e captação de energia solar, foram incorporadas ao projeto, promovendo eficiência energética sem abrir mão do conforto. A casa foi projetada para aproveitar ao máximo a topografia do terreno e a ventilação natural, criando uma harmonia perfeita entre os ambientes internos e externos.
              <br />
              A Casa Encontro é a síntese de uma arquitetura sofisticada, que combina inovação e design para proporcionar uma experiência única de moradia.
            </p>
          </div>
        </div>
      </div>

      <Exposition images={[
        "/assets/galeria/casa_2/Prancheta 1.png",
        "/assets/galeria/casa_2/Prancheta 2.png",
        "/assets/galeria/casa_2/Prancheta 3.png",
        "/assets/galeria/casa_2/Prancheta 4.png",
        "/assets/galeria/casa_2/Prancheta 5.png",
        "/assets/galeria/casa_2/Prancheta 6.png",
        "/assets/galeria/casa_2/Prancheta 7.png",
      ]} />
      <div className='Casas-description'>
        <p className='Typography-title'>
          Casa Cenário - Alphaville Graciosa | Pinhais
          <br />
          Arquitetura Contemporânea e Conforto em Harmonia com o Terreno
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Localizada no exclusivo condomínio Alphaville Graciosa, a casa projetada pelo escritório Fábia Teixeira Arquitetura aproveita o forte aclive do terreno de forma inteligente, utilizando-o como recurso para um design arquitetônico contemporâneo e sofisticado. O desafio de acomodar quatro suítes, área social e escritório em uma residência praticamente toda térrea foi solucionado de maneira criativa, com soluções que equilibram funcionalidade, estética e conforto.
              <br />
              A garagem, posicionada na frente do lote no nível da rua, evita grandes custos de escavação, enquanto a imponente escada de acesso principal à casa proporciona uma entrada marcante. O programa da casa é desenvolvido em um único pavimento, no qual a piscina se torna o centro da composição, sendo visível de diversos ambientes e proporcionando uma integração fluida entre o interior e o exterior.
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Um grande volume abriga as salas, cozinha e escritório, todos com vistas generosas para o campo verde ao redor e, ao mesmo tempo, conectados ao pátio interno da residência. A ventilação cruzada e a abundância de luz natural são características marcantes, garantindo conforto térmico e acústico, além de uma atmosfera acolhedora.
              <br />
              As suítes, com portas-janelas amplas, também se abrem para o pátio interno, promovendo continuidade espacial e uma sensação de amplitude. A escolha de materiais atemporais, como ripas de concreto, madeira e metal, conferem à residência uma estética contemporânea e sofisticada, que se alinha ao alto padrão do condomínio Alphaville Graciosa.
              <br />
              O projeto combina funcionalidade e bom gosto, com uma planta que abraça a piscina e oferece uma experiência de vida única, em um ambiente de luxo e bem-estar, integrado ao exuberante entorno natural.
            </p>
          </div>
        </div>
      </div>


      <Exposition images={[
        "/assets/galeria/casa_3/Prancheta 1.png",
        "/assets/galeria/casa_3/Prancheta 2.png",
      ]} />
      <div className='Casas-description'>
        <p className='Typography-title'>
          Casa Platôs
          <br />
          Arquitetura Contemporânea e Sofisticação em Terreno Desafiador
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Quando um casal de engenheiros, com uma visão clara do que desejava para sua casa dos sonhos, procurou o escritório Fábia Teixeira Arquitetura, a proposta foi transformar um projeto funcional em uma verdadeira obra-prima contemporânea. Com um entendimento profundo das necessidades e expectativas dos clientes, a arquiteta Fábia enfrentou um desafio empolgante: criar um projeto que respeitasse as premissas já estabelecidas pelos clientes, ao mesmo tempo em que incorporasse soluções inovadoras e elegantes, respeitando a topografia complexa do terreno.
              <br />
              Localizada em um condomínio de lotes generosos na região de Campo Largo – PR, a casa foi concebida em um terreno com aclive acentuado, exigindo um cuidadoso trabalho de escalonamento de níveis para que os espaços se conectassem de forma fluida, sem perder a harmonia e funcionalidade. A proposta foi, portanto, projetar um imóvel que se integrasse ao relevo de maneira inteligente, sem comprometer a elegância ou a coesão dos ambientes.
              <br />
              O resultado é uma casa luxuosa que, à primeira vista,
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              parece ser composta por dois pavimentos, mas, ao adentrar, revela-se uma residência de três andares, perfeitamente adaptada ao terreno. Cada nível foi projetado para garantir vistas deslumbrantes para gramado e para a piscina privativa, criando uma atmosfera de exclusividade e tranquilidade para a família.
              <br />
              A arquitetura moderna do projeto é marcada pela linguagem de volumes geométricos, que se entrelaçam de forma harmônica, criando uma identidade visual única. A escolha de materiais de alta qualidade, como concreto, vidro e madeira, em conjunto com texturas sofisticadas, adiciona profundidade e riqueza à composição, criando um ambiente acolhedor e contemporâneo.
              <br />
              A casa, que agora se encaixa de maneira fluida e elegante no terreno, foi projetada para proporcionar uma experiência de vida excepcional. Os clientes, extremamente satisfeitos com o resultado, desfrutam de um lar que não apenas reflete seus sonhos, mas também oferece um conforto sem igual, integrando modernidade e funcionalidade com a beleza natural do entorno.
            </p>
          </div>
        </div>
      </div>


      <Exposition images={[
        "/assets/galeria/casa_4/Prancheta 1.png",
        "/assets/galeria/casa_4/Prancheta 2.png",
      ]} />
      <div className='Casas-description'>
        <p className='Typography-title'>
          Casa Reserva
          <br />
          Arquitetura Imponente e Conforto Atemporal
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Localizada em um dos condomínios mais exclusivos da região, próximo ao Parque Barigui, a residência projetada pelo escritório Fábia Teixeira Arquitetura é um verdadeiro marco de sofisticação e funcionalidade. Em um lote de esquina, a casa foi concebida para ser imponente e moderna, ao mesmo tempo em que garante uma dinâmica de vida prática e confortável, perfeita para um casal com duas filhas. O projeto busca a harmonia entre espaços amplos e privados, criando um ambiente ideal para convivência familiar, sem abrir mão da elegância e do estilo contemporâneo.
              <br />
              O grande desafio do projeto foi, sem dúvida, a localização privilegiada da casa, sendo a mais exposta à entrada do condomínio. A arquiteta Fábia soube, com maestria, aproveitar a disposição do terreno para capturar abundante luz natural, sem comprometer a privacidade dos moradores. A solução foi criar volumes imponentes que se abrem para o entorno, mas com a inteligência de incorporar elementos que garantem a intimidade dos ambientes internos, criando um refúgio tranquilo e acolhedor.
              <br />
              A escolha de materiais de grande impacto, como aço corten e concreto aparente, foi decisiva para dar à residência uma estética moderna e atemporal, garantindo durabilidade e elegância. Esses elementos, aliados ao uso de grandes esquadrias e aberturas estratégicas, proporcionam luminosidade e ventilação cruzada, características essenciais
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              para o conforto dos moradores, especialmente nas três suítes, que foram posicionadas para receber o melhor da luz solar e ventilação natural.
              <br />
              A integração dos espaços também é uma das grandes qualidades deste projeto. Uma sacada ampla e linear conecta a volumetria da casa de forma harmoniosa, criando uma transição suave entre os ambientes internos e externos. As áreas sociais são amplas e abertas, favorecendo a convivência, enquanto as áreas íntimas da casa, como as suítes, oferecem total privacidade e conforto.
              <br />
              Além do projeto arquitetônico, os interiores foram desenhados com a mesma sensibilidade e atenção aos detalhes. O mobiliário, as cores e os acabamentos foram escolhidos para complementar a estética da residência, criando um ambiente acolhedor, elegante e funcional. Materiais nobres e de alta qualidade foram combinados com soluções de marcenaria, garantindo um espaço que é ao mesmo tempo sofisticado e prático.
              <br />
              O resultado final é uma casa que não apenas atende às necessidades do dia a dia de uma família moderna, mas que também se destaca como uma obra de arte funcional, projetada para ser um ícone atemporal. Com uma planta que equilibra o luxo e a praticidade, o projeto Holtz é um exemplo de como arquitetura de alta qualidade pode transformar o cotidiano de seus moradores, criando um lar que é, ao mesmo tempo, sofisticado e acolhedor.
            </p>
          </div>
        </div>
      </div>

      <Exposition images={[
        "/assets/galeria/casa_5/Prancheta 1.png",
        "/assets/galeria/casa_5/Prancheta 2.png",
        "/assets/galeria/casa_5/Prancheta 3.png",
        "/assets/galeria/casa_5/Prancheta 4.png",
        "/assets/galeria/casa_5/Prancheta 5.png",
        "/assets/galeria/casa_5/Prancheta 6.png",
        "/assets/galeria/casa_5/Prancheta 7.png",
        "/assets/galeria/casa_5/Prancheta 8.png",
      ]} />
      <div className='Casas-description'>
        <p className='Typography-title'>
          Casa Alma
          <br />
          Elegância, História e Sofisticação no coração de Curitiba
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Localizada em um dos bairros mais tradicionais e nobres de Curitiba, a casa projetada pelo escritório Fábia Teixeira Arquitetura é um verdadeiro tributo à história e à memória de uma família. O terreno, que por anos foi o lar da família desde a infância do cliente, carrega um apelo emocional profundo, e esse legado foi preservado e respeitado com maestria ao longo de todo o projeto. Para a arquiteta Fábia, o desafio foi integrar essa memória afetiva a uma nova visão contemporânea, criando um lar que fosse, ao mesmo tempo, um reflexo de sua história e uma expressão de sofisticação e modernidade, embora a construção seja inteira nova, vários elementos da casa original foram reaplicados, como pequenos azulejos na churrasqueira, poço artesiano, e ambientes que remetem a lembrança da casa original.
              <br />
              Com mais de 700 m², a casa foi projetada para acomodar a família com um nível excepcional de conforto, privacidade e elegância. O projeto contemporâneo e bem distribuído permite uma integração harmoniosa entre os espaços, sem abrir mão da possibilidade de momentos de introspecção e tranquilidade. As áreas sociais são amplas e convidativas, enquanto as suítes oferecem privacidade e iluminação natural abundante, criando um equilíbrio perfeito entre convivência e descanso.
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              O conceito de integração e fluidez foi aplicado de maneira inteligente, garantindo que os membros da família possam desfrutar de momentos de lazer juntos, ou se retirar para seus próprios espaços de privacidade, conforme desejado. A ala de lazer, com piscina e áreas externas, foi projetada de forma isolada do corpo principal da casa, garantindo tranquilidade e segurança, ao mesmo tempo em que se conecta de maneira sutil aos ambientes internos.
              <br />
              A arquitetura, de linhas modernas e sofisticadas, foi pensada para valorizar cada detalhe, respeitando a escala e as proporções do terreno, enquanto se adapta perfeitamente ao seu entorno. A casa Fendrich é um exemplo de como o design contemporâneo pode ser ao mesmo tempo ousado e acolhedor, oferecendo aos seus moradores um espaço cheio de possibilidades, charme e beleza.
              <br />
              Além do projeto arquitetônico, o escritório Fábia Teixeira Arquitetura também teve o prazer de conduzir a curadoria de interiores, cuidando meticulosamente de cada detalhe. A escolha de materiais nobres, acabamentos sofisticados e móveis de design garantiu que o resultado final fosse uma verdadeira obra-prima de interiores contemporâneos, oferecendo aos moradores um ambiente de luxo e bem-estar incomparável.
              <br />
              O resultado é uma casa atemporal, que mescla elegância, história e inovação em uma atmosfera única e acolhedora. A residência Fendrich não é apenas um lar, mas uma verdadeira experiência de vida sofisticada, desenhada para proporcionar conforto, funcionalidade e beleza em todos os seus detalhes.
            </p>
          </div>
        </div>
      </div>

      <Exposition images={[
        "/assets/galeria/casa_6/Prancheta 1.png",
        "/assets/galeria/casa_6/Prancheta 2.png",
        "/assets/galeria/casa_6/Prancheta 3.png",
        "/assets/galeria/casa_6/Prancheta 4.png",
        "/assets/galeria/casa_6/Prancheta 5.png",
        "/assets/galeria/casa_6/Prancheta 6.png",
        "/assets/galeria/casa_6/Prancheta 7.png",
        "/assets/galeria/casa_6/Prancheta 8.png",
      ]} />
      <div className='Casas-description'>
        <p className='Typography-title'>
          Casa Oásis
          <br />
          Arquitetura contemporânea e harmonia com a natureza em Quatro Barras
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Em um terreno excepcional de 5.000 m², situado em Quatro Barras, PR, o escritório Fábia Teixeira Arquitetura projetou uma casa que se desvia da convencionalidade para oferecer à família um verdadeiro refúgio, um oásis de tranquilidade em meio ao ritmo acelerado do cotidiano. Com uma tipologia de “casa pátio”, a residência adota uma forma em “U” que envolve e abraça o pátio interno, criando um espaço íntimo e exclusivo, onde a piscina é o elemento central, irradiando luz e frescor aos ambientes que a cercam.
              <br />
              Cada cômodo da casa foi projetado para ter vista para esse pátio convidativo, que se torna o coração da residência. A integração com a natureza é perfeita, com a casa projetada para não apenas conviver com o entorno, mas para ser parte dele, inserindo-se suavemente no cenário verde de forma orgânica. A relação entre os ambientes internos e o exterior é constante, permitindo que os moradores desfrutem da beleza do campo e da vegetação em cada canto da casa.
              <br />
              A área de lazer, estrategicamente posicionada em uma ala adjacente à casa principal, foi cuidadosamente projetada para garantir funcionalidade e fluidez. Embora separada fisicamente, a área de lazer mantém uma conexão visual e estética com o corpo principal da casa, por meio de elementos horizontais que unem os dois blocos. Isso cria uma
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              integração visual e espacial que facilita o uso dos ambientes sem comprometer a privacidade dos moradores, especialmente porque a casa principal, com seus ambientes mais privados, é independente da área social.
              <br />
              O projeto de linhas leves e contemporâneas, aliado à escolha de materiais naturais e sofisticados, como madeira e vidro, permite que a residência se insira de maneira harmônica no terreno, preservando a privacidade dos moradores, mas também favorecendo a convivência familiar em um ambiente acolhedor. As formas fluidas e as aberturas generosas garantem que a luz natural se faça presente em todos os espaços, criando uma atmosfera de bem-estar e serenidade.
              <br />
              A casa Giancarlo é um exemplo primoroso de como arquitetura contemporânea pode se aliar à natureza, criando um espaço luxuoso, mas igualmente acolhedor e funcional. Cada detalhe foi pensado para oferecer aos moradores um ambiente que exala sofisticação e conforto, ao mesmo tempo em que respeita o entorno e proporciona uma verdadeira imersão no verde. Ideal para quem busca não apenas uma residência, mas um estilo de vida em sintonia com a natureza e o luxo, sem abrir mão da privacidade e da exclusividade.
            </p>
          </div>
        </div>
      </div>


      <Exposition images={[
        "/assets/galeria/casa_7/Prancheta 1.png",
        "/assets/galeria/casa_7/Prancheta 2.png",
        "/assets/galeria/casa_7/Prancheta 3.png",
      ]} />
      <div className='Casas-description'>
        <p className='Typography-title'>
          Casa Horizonte – Campo Largo
          <br />
          Design funcional em terreno desafiador
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Localizada em um terreno de forte declive no exclusivo condomínio Gaya, em Campo Largo, esta residência foi projetada para tirar o máximo proveito da topografia e da vista deslumbrante para o campo. O projeto inteligente e sofisticado posiciona a garagem e o setor de serviços no subsolo, aproveitando o declive de forma estratégica, e liberando o platô superior para as áreas sociais e íntimas, criando um equilíbrio perfeito entre funcionalidade e estética.
              <br />
              A área social, no pavimento principal, é um dos grandes destaques da casa. Com um amplo vão livre, ela integra as salas de estar, jantar e a área gourmet, criando um espaço fluido e sofisticado, ideal para convívio e recepção. As generosas aberturas garantem iluminação natural abundante e emolduram a vista panorâmica, trazendo o campo para dentro da casa de forma imersiva. O ponto alto deste nível é a piscina com borda infinita, que se estende
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              visualmente para o horizonte, ampliando ainda mais a sensação de continuidade entre o interior e a paisagem natural.No pavimento superior, as quatro suítes foram projetadas para aproveitar ao máximo essa vista privilegiada, com grandes aberturas que garantem luminosidade e ventilação naturais, além de um ambiente de tranquilidade e conforto.
              <br />
              Com uma arquitetura contemporânea, o projeto destaca-se pela leveza das linhas e pela integração impecável com a natureza ao redor. A escolha de materiais sofisticados, como vidro, madeira e concreto, aliada à fluidez dos espaços, cria uma residência de alta qualidade que é, sem dúvida, um ícone arquitetônico. Uma casa que não só se adapta ao terreno, mas celebra sua paisagem, oferecendo aos moradores uma experiência de moradia única e memorável.
            </p>
          </div>
        </div>
      </div>

      <Exposition images={[
        "/assets/galeria/casa_8/Prancheta 1.png",
        "/assets/galeria/casa_8/Prancheta 2.png",
        "/assets/galeria/casa_8/Prancheta 3.png",
      ]} />
      <div className='Casas-description'>
        <p className='Typography-title'>
          Casa Raízes
          <br />
          Conforto e funcionalidade para família em crescimento
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Em um condomínio exclusivo em Colombo, o escritório Fábia Teixeira Arquitetura projetou uma casa para uma família em crescimento, com a premissa de ser espaçosa, mas sem excessos. A residência foi pensada para oferecer conforto e acomodar a convivência familiar e eventos, ao mesmo tempo em que preserva amplos espaços livres para as crianças brincarem.
              <br />
              A casa foi concebida com foco na integração com o vasto terreno nos fundos, criando uma conexão fluida entre os ambientes internos e a natureza ao redor. A arquitetura biofílica, com grandes aberturas, garante luz natural e ventilação, enquanto o jardim amplo se torna uma extensão das áreas sociais.
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Com uma planta moderna e bem distribuída, a casa conta com espaços amplos e funcionais, como salas de estar e jantar que se abrem para o exterior, promovendo integração. Materiais sofisticados como madeira e concreto aparente conferem um design contemporâneo e atemporal.
              <br />
              A residência também incorpora soluções sustentáveis, como captação de água da chuva, painéis solares e automação energética, proporcionando conforto e eficiência no dia a dia.
              <br />
              Este projeto reflete o equilíbrio entre estética, funcionalidade e qualidade de vida, criando um lar ideal para uma família que cresce, com foco na convivência e bem-estar.
            </p>
          </div>
        </div>
      </div>

      <Exposition images={[
        "/assets/galeria/casa_9/Prancheta 1.png",
        "/assets/galeria/casa_9/Prancheta 2.png",
        "/assets/galeria/casa_9/Prancheta 3.png",
        "/assets/galeria/casa_9/Prancheta 4.png",
        "/assets/galeria/casa_9/Prancheta 5.png",
        "/assets/galeria/casa_9/Prancheta 6.png",
        "/assets/galeria/casa_9/Prancheta 7.png",
        "/assets/galeria/casa_9/Prancheta 8.png",
        "/assets/galeria/casa_9/Prancheta 9.png",
        "/assets/galeria/casa_9/Prancheta 10.png",
        "/assets/galeria/casa_9/Prancheta 11.png",
        "/assets/galeria/casa_9/Prancheta 12.png",
      ]} />
      <div className='Casas-description'>
        <p className='Typography-title'>
          Casa Asa Delta
          <br />
          Projeto Residencial no Parque Barigui – Curitiba
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>
              Em um terreno desafiador, com um relevo acentuado e um desenho incomum, o escritório Fábia Teixeira Arquitetura foi chamado para criar uma casa que respeitasse as particularidades do local, sem abrir mão da estética e funcionalidade. O resultado foi uma residência de design inovador, em formato de "asa delta", que se integra de forma harmônica ao terreno e à natureza ao redor.
              <br />
              A proposta, cuidadosamente desenhada, garante que, ao circular pela casa, o visitante tenha sempre a visão do jardim central. Esse elemento se torna o coração da casa, sendo visível a todo momento, criando uma sensação de continuidade entre o interior e o exterior. A casa se divide em duas alas, que ladeiam o jardim, organizando os espaços de forma fluida e funcional. A distribuição do programa arquitetônico foi pensada para garantir que os ambientes fossem aproveitados de maneira inteligente, com a luz natural e a ventilação cruzada como protagonistas em cada.
              <br />
              Os clientes, um casal de personalidade discreta e gentil, com grande apreço pela privacidade e pelo conforto sem ala.
            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>
              ostentação, influenciaram diretamente as escolhas feitas. O projeto de interiores foi desenvolvido com grande sensibilidade, respeitando o estilo e os valores do casal. A casa, embora sofisticada, transmite uma sensaçãode aconchego e bem-estar, sem exageros, e com um cuidado especial para que a privacidade fosse preservada em todos os momentos.
              <br />
              Cada detalhe foi pensado para manter a fluidez do ambiente, com soluções contemporâneas e materiais que refletem elegância e atemporalidade. A casa não só acolhe os moradores com conforto, mas também integra-se de maneira sublime ao terreno e ao contexto natural ao seu redor, criando uma verdadeira obra de arquitetura e design que celebra a harmonia com o ambiente.
              <br />
              Este projeto é um exemplo de como é possível criar um lar que respeite a personalidade de seus moradores, ao mesmo tempo em que se ajusta de maneira inteligente ao terreno e ao contexto, oferecendo uma experiência de morar única, sofisticada e acolhedora
            </p>
          </div>
        </div>
      </div>

    </div>
  )
}

/*
      <Exposition images={[
      ]} />
      <div className='Casas-description'>
        <p className='Typography-title'>
        </p>
        <div className='Layout-row'>
          <div className='Layout-50'>
            <p className='Typography-body'>

            </p>
          </div>
          <div className='Layout-50'>
            <p className='Typography-body'>

            </p>
          </div>
        </div>
      </div>

*/