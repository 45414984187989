import ContactForm from "../components/contact-form"
import { PageTitle } from "../components/page-title"
import "./contato.scss"

export const ContatoPage = () => {
  return (
    <div className='ContatoPage'>
      <PageTitle title="Fábia Teixeira | Contato" />

      <div className="Section Layout-row">
        <div className="Layout-50 Section-image">
          <img src={process.env.PUBLIC_URL + '/assets/contato_1.avif'} />
        </div>

        <div className="Layout-50 Section-text">
          <h2 className="Typography-title">
            Vamos conversar!
          </h2>

          <p className="Typography-body">
            Se você busca um projeto único e exclusivo, estamos à disposição para transformar sua visão em realidade. Agende uma reunião conosco para discutir suas expectativas e requisitos de proposta. Juntos, analisaremos o conceito de seu projeto, avaliando com cuidado o seu investimento e como podemos atender às suas necessidades de forma personalizada e diferenciada. Será um prazer recebê-lo em nosso escritório, onde transformamos sonhos em experiências únicas, criamos soluções sob medida para cada cliente.
          </p>

          <div className="Form">
            <ContactForm />

            <p className="form-disclaimer">
              Autorizo que os meus dados sejam armazenados de acordo com as diretrizes estabelecidas Lei Geral de Proteção de Dados Pessoais (LGPD)
            </p>
          </div>

          <h2 className="Typography-title">
            CARREIRA - FAÇA PARTE NESTE ESCRITÓRIO
          </h2>

          <p className="Typography-body">
            Torne-se um membro da nossa equipe, no Escritório FT Arquitetura buscamos sempre novos talentos.
            <br />
            <br />
            Envie um e-mail para <a href="mailto:fabia@teixeira.arq.br" className="underline inline">fabia@teixeira.arq.br</a> nos contando mais sobre você, seus sonhos e ambições.
            <br />
            <br />
            Não esqueça de anexar seu Currículo, carta de apresentação e portfólio.
            <br />
            Este pode ser o começo de um ótima oportunidade para sua carreira!
          </p>

        </div>
      </div>

    </div>
  )
}
