import { Instagram } from "../common/instagram"
import { Pinterest } from "../common/pinterest"
import { PageTitle } from "../components/page-title"
import "./dna.scss"

export const DnaPage = () => {
  return (
    <div className='DnaPage'>
      <PageTitle title="Fábia Teixeira | DNA" />
      <div className="Section Layout-row">
        <div className="Layout-50 Section-text Section-limit">
          <h2 className="Typography-title">
            Paixão por criar projetos contemporâneos com foco na excelência.
          </h2>

          <p className="Typography-body">
            Cada projeto que criamos é uma jornada única, onde transformamos as aspirações e o estilo de vida de nossos clientes em ambientes exclusivos, que transcendem a mera estética. Valorizamos o conforto, a funcionalidade e o bem-estar, garantindo que cada detalhe seja pensado com precisão.
            <br />
            Acreditamos que a arquitetura é uma forma de expressão, por isso trabalhamos de maneira artesanal, aliando criatividade, as mais recentes inovações tecnológicas e as últimas tendências globais. Cada elemento do projeto é cuidadosamente planejado para refletir a essência de quem o habitará. Desde a seleção rigorosa de materiais de altíssima qualidade até a personalização de soluções arquitetônicas, nosso compromisso é entregar espaços verdadeiramente exclusivos, com um conceito de morar que exala personalidade e sofisticação.
          </p>

          <div className="Section-social">
            <a href="https://www.instagram.com/fabiateixeiraarquitetura/">
              <Instagram />
            </a>
            <a href="https://br.pinterest.com/FABIATEIXEIRAARQUITETURA/">
              <Pinterest />
            </a>
          </div>
        </div>

        <div className="Layout-50 Section-image">
          <img src={process.env.PUBLIC_URL + '/assets/dna_1.avif'} />
        </div>
      </div>

      <div className="Image">
        <img src={process.env.PUBLIC_URL + '/assets/dna_2.avif'} />
      </div>

      <div className="Section Layout-row Dna-text">
        <div className="Section-text">
          <h2 className="Typography-title">
            Nossa filosofia de design
          </h2>

          <div className="Layout-row">
            <div className="Layout-50">
              Somos apaixonadamente motivados a conceber projetos contemporâneos de Arquitetura e curadoria Design de Interiores que buscam atemporalidade e focam em excelência, conforto, luxo e bem-estar.
            </div>
            <div className="Layout-50">
              Respeito pela natureza, devoção a materiais nobres, capacidade de descobrir nuances sutis, foco inegável e atenção rigorosa a cada detalhe e um instinto para equilibrar tecnologia com peças artesanais são partes essenciais da nossa filosofia de design.
            </div>
          </div>

        </div>
      </div>

      <div className="Image">
        <img src={process.env.PUBLIC_URL + '/assets/dna_3.avif'} />
      </div>

      <div className="Section Layout-row Dna-text">
        <div className="Section-text">
          <h2 className="Typography-title">
            A beleza da simplicidade
          </h2>

          <div className="Layout-row">
            <div className="Layout-50">
              Nossos projetos são desenvolvidos em torno de uma compreensão contemporânea do que é luxo.
              <br />
              Nosso conceito de luxo não é baseado em custo ou excesso, mas no prazer fundamental de viver, estar imerso na beleza visual e tátil. Um tipo de luxo que não foca no externo, mas sim na satisfação e bem-estar interno.
            </div>
            <div className="Layout-50">
              Em cada projeto, nosso centro de atenção está na qualidade espacial, na sofisticação descontraída, na elegância atemporal, na precisão da engenharia, na beleza da simplicidade, na definição cuidadosa dos detalhes e na delicadeza das texturas, bem como no controle da luz.
            </div>
          </div>

        </div>
      </div>

      <div className="Image">
        <img src={process.env.PUBLIC_URL + '/assets/dna_4.avif'} />
      </div>

      <div className="Section Layout-row Dna-text">
        <div className="Section-text">
          <h2 className="Typography-title">
            Além do design
          </h2>

          <div className="Layout-row">
            <div className="Layout-50">
              Para alcançar a excelência em projetos, é essencial contar com equipes qualificadas, com experiência comprovada e eficácia reconhecida. Cada membro deve compreender as necessidades dos clientes, antecipar desafios e gerenciar riscos de forma eficiente, garantindo a execução e entrega com precisão e agilidade.
            </div>
            <div className="Layout-50">
              O Escritório Fábia Teixeira combina o conhecimento das últimas tendências com a busca pela exclusividade, criando projetos únicos e sofisticados que se destacam pela sua singularidade e excelência.
            </div>
          </div>

        </div>
      </div>

      <div className="Image">
        <img src={process.env.PUBLIC_URL + '/assets/dna_5.avif'} />
      </div>

      <div className="Section Layout-row Dna-text">
        <div className="Section-text">
          <h2 className="Typography-title">
            Metodologia
          </h2>

          <div className="Layout-row">
            <div className="Layout-50">
              No Escritório Fábia teixeira, para alcançar os melhores resultados, construímos cada novo projeto de forma personalizada, focamos nos desejos e aspirações das pessoas e nos objetivos do conceito ”Morar Bem”.
              <br />
              Criamos um processo próximo e personalizado que leva em consideração uma experiência de cliente excepcional e sem complicações, por meio da combinação dos especialistas necessários em cada caso. Garantimos uma abordagem coordenada, maior atenção aos detalhes e
            </div>
            <div className="Layout-50">
              reduzimos preocupações e mal-entendidos. Nossa equipe  é formada pelos melhores arquitetos, designers de interiores, paisagistas, designers de iluminação, artistas 3D, analistas de investimentos e gerentes de construção, entre outros.
              <br />
              Agregamos valor ao projeto e à experiência do cliente empregando as mais recentes e sofisticadas soluções digitais, modelamos cada projeto em 3D de alta tecnologia e compartilhamos imagens espaciais para mostrar aos nossos clientes os resultados exatos que buscamos.
            </div>
          </div>

        </div>
      </div>

      <div className="Image">
        <img src={process.env.PUBLIC_URL + '/assets/dna_6.avif'} />
      </div>

      <div className="Section Layout-row Dna-text">
        <div className="Section-text">
          <h2 className="Typography-title">
            Um processo centrado no cliente
          </h2>

          <div className="Layout-row">
            <div className="Layout-50">
              Quando um novo cliente chega ao Escritório Fábia Teixeira, a equipe traça um roteiro claro e personalizado para gerenciar um serviço completo do início ao fim, seja um projeto arquitetônico residencial de luxo ou uma curadoria especializada em design de interiores.
            </div>
            <div className="Layout-50">
              Nosso objetivo é que o cliente aproveite o processo e a beleza do resultado, identificando os desafios do projeto, estabelecendo tempos, custos, marcos relevantes e momentos-chave de interação. Por esse motivo, no Escritório Fábia Teixeira  garantimos que capturamos e incorporamos a essência do estilo de vida ou identidade da  do cliente.
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}
